// Slick slider settings
const mid_slider_settings = {
	slidesToShow: 1,
	slidesToScroll: 1,
	dots: true,
	centerMode: false,
	focusOnSelect: true,
	mobileFirst: true,
	infinite: false,
	arrows: false,
};

// Base slider settings for resume sliders
const resumeSliderBaseOptions = {
	infinite: true,
	speed: 600,
	centerMode: true,
	variableWidth: true,
	centerPadding: "60px",
	slidesToShow: 3,
	cssEase: "linear",
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				centerMode: true,
				centerPadding: "30px",
				slidesToShow: 3,
			},
		},
		{
			breakpoint: 767,
			settings: {
				arrows: false,
				centerMode: true,
				centerPadding: "20px",
				slidesToShow: 1,
				dots: true,
				infinite:false,
			},
		},
	],
};

// Slider-specific options
const resumeslider1_options = {
	...resumeSliderBaseOptions,
	prevArrow: "<button id='sample-prev1' class='sampleprev-btn sample-circle'><i class='far fa-arrow-left' aria-hidden='true'></i></button>",
	nextArrow: "<button id='sample-next1' class='samplenext-btn sample-circle'><i class='far fa-arrow-right' aria-hidden='true'></i></button>",
};

const resumeslider2_options = {
	...resumeSliderBaseOptions,
	dots: true,
	prevArrow: "<button id='sample-prev2' class='sampleprev-btn sample-circle'><i class='far fa-arrow-left' aria-hidden='true'></i></button>",
	nextArrow: "<button id='sample-next2' class='samplenext-btn sample-circle'><i class='far fa-arrow-right' aria-hidden='true'></i></button>",
};

const resumeslider3_options = {
		infinite: true,
		speed: 600,
		centerMode: true,
		dots: false,
		arrows: true,
		variableWidth: true,
		centerPadding: "60px",
		slidesToShow: 3,
		cssEase: "linear",
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					centerMode: true,
					centerPadding: "30px",
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 767,
				settings: {
					centerMode: true,
					arrows: true,
					centerPadding: "20px",
					slidesToShow: 1,
				},
			},
		],
	prevArrow: "<button id='preview-prev' class='sampleprev-btn sample-circle btn-slider-left' style='display:block!important;'><i class='far fa-arrow-left' aria-hidden='true'></i></button>",
	nextArrow: "<button id='preview-next' class='samplenext-btn sample-circle btn-slider-right' style='display:block !important;'><i class='far fa-arrow-right' aria-hidden='true'></i></button>",
};

// Throttle function to limit the number of calls to resize event
function throttle(func, limit) {
	let lastFunc;
	let lastRan;
	return function () {
		const context = this;
		const args = arguments;
		if (!lastRan) {
			func.apply(context, args);
			lastRan = Date.now();
		} else {
			clearTimeout(lastFunc);
			lastFunc = setTimeout(function () {
				if (Date.now() - lastRan >= limit) {
					func.apply(context, args);
					lastRan = Date.now();
				}
			}, limit - (Date.now() - lastRan));
		}
	};
}

// Initialize sliders in a non-blocking manner
function initSliders() {
	const wv = $(window).width();
	const $sliders = [
		{ element: $("#resumeSlider1"), settings: resumeslider1_options },
		{ element: $("#resumeSlider2"), settings: resumeslider2_options },
		{ element: $("#resumeSlider3"), settings: resumeslider3_options }
	];
	const $middSectionLayout = $(".midd-section-layout");

	// Defer initialization of sliders for non-blocking
	$sliders.forEach(({ element, settings }) => {
		if (!element.hasClass("slick-initialized")) {
			requestIdleCallback(() => {
				element.slick(settings);
			});
		}
	});

	// Initialize middle section slider for small screens only, defer if possible
	if (wv <= 767 && !$middSectionLayout.hasClass("slick-initialized")) {
		requestIdleCallback(() => {
			$middSectionLayout.slick(mid_slider_settings);
		});
	}
}

// Handle window resize for refreshing sliders
function refreshSlidersOnResize() {
	const wv = $(window).width();
	const $resumeSlider1 = $("#resumeSlider1");
	const $resumeSlider2 = $("#resumeSlider2");
	const $resumeSlider3 = $("#resumeSlider3");
	const $middSectionLayout = $(".midd-section-layout");

	// Refresh sliders only if initialized
	requestIdleCallback(() => {
		if ($resumeSlider1.hasClass("slick-initialized")) {
			$resumeSlider1.slick("refresh");
		}
		if ($resumeSlider2.hasClass("slick-initialized")) {
			$resumeSlider2.slick("refresh");
		}
		if ($resumeSlider3.hasClass("slick-initialized")) {
			$resumeSlider3.slick("refresh");
		}
	});

	// Toggle the middle section slider based on screen size
	if (wv > 767) {
		if ($middSectionLayout.hasClass("slick-initialized")) {
			$middSectionLayout.slick("unslick");
		}
	} else {
		if (!$middSectionLayout.hasClass("slick-initialized")) {
			requestIdleCallback(() => {
				$middSectionLayout.slick(mid_slider_settings);
			});
		} else {
			requestIdleCallback(() => {
				$middSectionLayout.slick("refresh");
			});
		}
	}
}

// Initialize sliders after page load without blocking the main thread
jQuery(document).ready(function () {
	if ('requestIdleCallback' in window) {
		requestIdleCallback(initSliders);
	} else {
		// Fallback for browsers that do not support requestIdleCallback
		setTimeout(initSliders, 200);
	}
});

// Throttle resize events to improve performance
$(window).resize(throttle(refreshSlidersOnResize, 200));

// function load_missed_lazy_images() {
// 	const missedLazyloadImages = document.querySelectorAll("#resumeSlider1 .lazy, #resumeSlider2 .lazy, .midd-section-layout .lazy");
// 	if (missedLazyloadImages != '' && missedLazyloadImages != null) {
// 		missedLazyloadImages.forEach(function (img) {
// 			img.src = img.dataset.src;
// 			img.classList.remove("lazy");
// 			img.removeAttribute("loading");
// 			img.classList.remove("lazy-hidden");
// 			img.classList.add("lazy-loaded");
// 		});
// 	}
// }

var checkLazyLoad = true;
function lazyLoadOnScroll() {
	if (checkLazyLoad) {
		var imgs = document.getElementsByClassName("lazy-onscrolled");
		for (var x = 0; x < imgs.length; x++) {
			var item = imgs[x];
			if ((item.tagName).toLowerCase() == 'picture') {
				var sources = item.querySelectorAll('source');
				if (sources != '' && sources != null) {
					sources.forEach(function (source) {
						var src = source.dataset.srcset;
						if (src != '' && src != null) {
							source.srcset = src;
							source.removeAttribute('data-srcset');
						}
					});
				}
				var imgElem = item.querySelector('img');
				var src = imgElem.getAttribute('data-orig_src');
				if (src != '' && src != null) {
					imgElem.src = src;
					imgElem.removeAttribute('data-orig_src');
				}
			} else {
				var src = item.getAttribute('data-orig_src');
				if (src != '' && src != null) {
					item.setAttribute('src', src);
					item.removeAttribute('data-orig_src');
					item.classList.remove("lazy-onscrolled");
				}
			}
		}
		checkLazyLoad = false;
	}
}

// Event listener for scroll
window.addEventListener('scroll', lazyLoadOnScroll);

// Handle accordion checkbox clicks
$(document).on("click", ".accordian-checkbox", function () {
	$(".accordian-checkbox").not(this).prop("checked", false);
});

/**
			 * Lazy load some specific images here
			 */

/*function recheck() {
	var window_top = $(this).scrollTop();
	var window_height = $(this).height();
	var view_port_s = window_top;
	var view_port_e = window_top + window_height;
	$(".animatedSection").each(function () {
		var block = $(this);
		var block_top = block.offset().top;
		var block_height = block.height();

		if (block_top <= view_port_e) {
			block.addClass("fadeInUp");
		} else {
			block.removeClass("fadeInUp");
		}
	});
}*/

/*$(function () {
	$(window).scroll(function () {
		recheck();
	});

	$(window).resize(function () {
		recheck();
	});

	recheck();
	var windowWidth = $(window).width();
	var currentDiv = $("#animateBox1,#animateBoxHead1");
	var nextDiv,
		count = 1;
	var timesRun = 0;

	var myInterval = setInterval(function () {
		if (count == 4) {
			currentDiv.hide();
			currentDiv.removeClass("outAnimation");
			currentDiv = $("#animateBox1,#animateBoxHead1");
			currentDiv.show();
			currentDiv.addClass("outAnimation");
			count = 1;
		} else {
			count++;
			currentDiv.hide();
			currentDiv.removeClass("outAnimation");
			currentDiv = currentDiv.next();
			currentDiv.show();
			currentDiv.addClass("outAnimation");
		}
	}, 5000);

	
});*/
