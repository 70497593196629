    const body = document.body;
    // Mobile menu bar toggle
    const mobileMenuBars = document.querySelectorAll(".mobile-menu-bar");
    if (mobileMenuBars.length > 0) {
        mobileMenuBars.forEach(element => {
            element.addEventListener("click", () => {
                const menuLinksMobile = document.querySelector(".menu-links-mobile");
                if (menuLinksMobile) {
                    menuLinksMobile.classList.add("show-menu");
                    body.classList.add("no-scroll");
                }
            });
        });
    }

    // Close menu toggle
    const closeIcons = document.querySelectorAll(".close-icon");
    if (closeIcons.length > 0) {
        closeIcons.forEach(element => {
            element.addEventListener("click", () => {
                const menuLinksMobile = document.querySelector(".menu-links-mobile");
                if (menuLinksMobile) {
                    menuLinksMobile.classList.remove("show-menu");
                    body.classList.remove("no-scroll");
                }
            });
        });
    }

    const winWidth = window.innerWidth;
    if (winWidth < 992) {

        // Mobile hamburger menu
        const mobileHamburger = document.querySelector(".mobile-humbarger");
        if (mobileHamburger) {
            mobileHamburger.addEventListener("click", function () {
                const icon = this.querySelector("i.far") || this.querySelector("i.ficon");
                const topHeader = this.closest(".topHeader");
                if (topHeader) {
                    const megaMenuWrap = topHeader.querySelector(".mega-menu-wrap");
                    const searchBtn = topHeader.querySelector(".searchBtn");
                    const navTop = topHeader.querySelector(".navTop");
                    const searchSection = topHeader.querySelector(".search-section");
                    if (megaMenuWrap) megaMenuWrap.classList.toggle("show");
                    if (searchBtn) searchBtn.classList.toggle("hide");
                    if (navTop) navTop.classList.toggle("height-90");
                    if (searchSection) searchSection.classList.remove("is-active");
                    if (searchBtn) {
                        const searchIcon = searchBtn.querySelector(".ficon");
                        if (searchIcon) {
                            searchIcon.classList.remove("fa-times");
                            searchIcon.classList.add("fa-search");
                        }
                    }
                    if (icon) {
                        icon.classList.toggle("fa-bars");
                        icon.classList.toggle("fa-times");
                    }

                }
            });
        }
    }

    // Search button functionality
    const searchBtn = document.querySelector(".searchBtn");
    if (searchBtn) {
        searchBtn.addEventListener("click", function (event) {
            event.stopPropagation();
            const sIcon = this.querySelector("i.far") || this.querySelector("i.ficon");
            const topHeader = this.closest(".topHeader");
            if (topHeader) {
                const searchSection = topHeader.querySelector(".search-section");
                if (searchSection) searchSection.classList.toggle("is-active");
                const searchBox = document.getElementById("wpSearchBox");
                const searchResult = document.getElementById("wpSearchResult");
                if (searchBox) searchBox.value = "";
                if (searchResult) searchResult.classList.remove("show-serch-dropdown");
                if (sIcon) {
                    sIcon.classList.toggle("fa-search");
                    sIcon.classList.toggle("fa-times");
                }
            }
        });
    }

   


// if(document.querySelectorAll(".mobile-menu-bar").length > 0){
//     document.querySelectorAll(".mobile-menu-bar").forEach(function (element) {
//         element.addEventListener("click", function () {
//             document.querySelector(".menu-links-mobile").classList.add("show-menu");
//             document.body.classList.add("no-scroll");
//         });
//     });
// }


// if(document.querySelectorAll(".close-icon").length > 0){
//     document.querySelectorAll(".close-icon").forEach(function (element) {
//         element.addEventListener("click", function () {
//             var selectorLink =   document.querySelector(".menu-links-mobile");
//             if(selectorLink){
//                 selectorLink.classList.contains("show-menu").remove("show-menu");
//             }
//             document.body.classList.contains("no-scroll").remove("no-scroll");
//         });
//     });
// }


// Explore mobile humbarger open
// var winwidth = window.innerWidth;
// if (winwidth < 992) {
//     document.querySelector(".mobile-humbarger").addEventListener("click", function () {
//         var icon = this.querySelector("i.far") || this.querySelector("i.ficon");
//         icon.classList.remove("fa-times");
//         var megaMenuWrap = this.closest(".topHeader").querySelector(".mega-menu-wrap");
//         var searchBtn = this.closest(".topHeader").querySelector(".searchBtn");
//         var navTop = this.closest(".topHeader").querySelector(".navTop");
//         var searchSection = this.closest(".topHeader").querySelector(".search-section");
//         megaMenuWrap.classList.toggle("show");
//         searchBtn.classList.toggle("hide");
//         navTop.classList.toggle("height-90");
//         searchSection.classList.remove('is-active');
//         searchBtn.querySelector('.ficon').classList.remove('fa-times');
//         searchBtn.querySelector('.ficon').classList.add('fa-search');

//         if (icon.classList.contains("fa-bars")) {
//             icon.classList.remove("fa-bars");
//             icon.classList.add("fa-times");
//         } else {
//             icon.classList.add("fa-bars");
//         }
//     });
// }

//Explore serch bar

// document.querySelector(".searchBtn").addEventListener("click", function (event) {
//     event.stopPropagation();
//     var sIcon = this.querySelector("i.far") || this.querySelector("i.ficon");
//     sIcon.classList.remove("fa-times");
//     var searchSection = this.closest(".topHeader").querySelector(".search-section");

//     searchSection.classList.toggle("is-active");
//     document.getElementById('wpSearchBox').value = "";
//     document.getElementById('wpSearchResult').classList.remove("show-serch-dropdown");

//     if (sIcon.classList.contains("fa-search")) {
//         sIcon.classList.remove("fa-search");
//         sIcon.classList.add("fa-times");
//     } else {
//         sIcon.classList.add("fa-search");
//     }
// });




var isSessionStorageAvailable = function () {
    var testKey = 'test', storage = window.sessionStorage;
    try {
        if (storage) {
            storage.setItem(testKey, '1');
            storage.removeItem(testKey);
            return true;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}
function setTemplate(skinCD) {
    if (isSessionStorageAvailable()) {
        sessionStorage.setItem('selectedSkinCD', skinCD);
    }
    window.location.href = "/builder/how-it-works";
}

function searchWP(e) {
    e.preventDefault();
    searchArticles(document.getElementById('wpSearchBox').value);
}
function searchArticles(text) {
    if (text && text.length > 2) {
        var url = "/home/searcharticles" + "?text=" + text + "&num=3";
        if (window.XMLHttpRequest) {
            // code for modern browsers
            var xmlhttp = new XMLHttpRequest();
        } else {
            // code for old IE browsers
            var xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
        }
        xmlhttp.onreadystatechange = function () {
            if (this.readyState == 4 && this.status == 200) {
                var response = JSON.parse(xmlhttp.responseText);
                var searchResultHTML = '';
                if (response.length > 0) {
                    document.getElementById('wpSearchResult').classList.remove("no-result-dropdown");
                    searchResultHTML = '<ul>'
                    for (var i = 0; i < response.length; i++) {
                        searchResultHTML += '<li><a target="_blank" href = "' + response[i].slug + '"><div class="search-list-img"><img src = "' + response[i].feautured_image.thumbnail[0] + '" alt = "tips image" height="120" width="120" /></div><div class="serch-list-text"><h3>'
                            + response[i].title + '</h3><p>' + response[i].description + '</p></div></a></li>';
                    }
                    searchResultHTML += '</ul>';
                }
                else {
                    document.getElementById('wpSearchResult').classList.add("no-result-dropdown");
                    searchResultHTML = '<p class="searchResultNotFount">Sorry, no results found</p>';
                }
                document.getElementById('wpSearchResult').innerHTML = searchResultHTML;
                document.getElementById('wpSearchResult').classList.add("show-serch-dropdown");
            }
        };
        xmlhttp.open("GET", url, true);
        xmlhttp.send();
    }
    else {
        document.getElementById('wpSearchResult').innerHTML = '';
        document.getElementById('wpSearchResult').classList.remove("show-serch-dropdown");
    }
}


    const els = document.querySelectorAll('a[href$="how-it-works"]');     
    const addQuery = (el) => {
        if (!el.href) return;
        el.addEventListener("click", () => {
            const currentUrl = window.location.href;
            const queryStartIndex = currentUrl.indexOf("?");
            const params = queryStartIndex >= 0 ? currentUrl.slice(queryStartIndex + 1) : "";
            if (!el.href.includes("?")) {
                el.href += params ? `?${params}` : "";
            }
        });
    };
    els.forEach(addQuery);

//Multiple options dropdown

// const optionMenu = document.querySelector(".select-menu"),
//   selectBtn = optionMenu.querySelector(".select-btn"),
//   options = optionMenu.querySelectorAll(".option"),
//   sBtn_text = optionMenu.querySelector(".sBtn-text"),
//   sBtn_img = optionMenu.querySelector(".sBtn-img");
// selectBtn.addEventListener("click", () =>
//   optionMenu.classList.toggle("active")
// );

// options.forEach((option) => {
//   option.addEventListener("click", () => {
//     let selectedOption = option.querySelector(".option-text").innerText;
//       sBtn_text.innerText = selectedOption;

//     let selectedOptionImg = option.querySelector(".option-img").innerHTML;

//     sBtn_img.innerHTML = selectedOptionImg;

//     optionMenu.classList.remove("active");
//   });
// });



// new Div Element created based on video Element present in Hero banner
var isVideo = document.querySelector(".hero-banner .is-video");
if (isVideo != null){
    var videoSpan = isVideo.querySelector("#video");
        isVideo.querySelector("#video").addEventListener("click", function(e) {
         var videoLink = videoSpan.getAttribute('data-modal-video');
         var ctamodal = document.getElementById("cta-modalvideo");
        
         var vidSource = document.createElement("source");
         vidSource.setAttribute('id', "vidSource");
         vidSource.setAttribute('src', videoLink);
         vidSource.setAttribute('type', 'video/mp4');
        var videoModal = ctamodal.querySelector("#videoModal");
        videoModal.appendChild(vidSource);
        videoModal.play();
        ctamodal.style.display = "block";

        var closecta = document.getElementsByClassName("close")[0];

        closecta.onclick = function () {
            videoModal.pause();
            ctamodal.style.display = "none";
        }
        document.body.onclick = function (event) {
            if (event.target == ctamodal) {
                videoModal.pause();
                ctamodal.style.display = "none";
            }
        }
   
   });

// =============================================================================



// ==============  videoModal =====================
//        function openVideo() {
//            var ctamodal = document.getElementById("cta-modalvideo");
//           document.getElementById("videoModal").play();
//            ctamodal.style.display = "block";          
//         }
//         var videoModal = document.getElementById("videoModal");
//         var check_controls_list = videoModal.hasAttribute('controlsList');
//         var check_pip = videoModal.hasAttribute('disablePictureInPicture');
//         if (!check_controls_list){
//             videoModal.setAttribute('controlsList', 'nodownload');
//         }
//         if (!check_pip){
//             videoModal.setAttribute('disablePictureInPicture', 'true');
//         }
//         var ctamodal = document.getElementById("cta-modalvideo");
//         var closecta = document.getElementsByClassName("close")[0];

//         closecta.onclick = function () {
//             document.getElementById("videoModal").pause();
//             ctamodal.style.display = "none";
//         }
//         document.body.onclick = function (event) {
//             if (event.target == ctamodal) {
//                 document.getElementById("videoModal").pause();
//                 ctamodal.style.display = "none";
//             }
//         }
   
//             // document.getElementById('vidSource').src = 'https://rncdn1ak.azureedge.net/video/Nerd-DemoVideo.mp4';
//     document.querySelector("video").load();

} 


function openVideo() {
    var ctamodal = document.getElementById("cta-modalvideo");
    var videoModal = document.getElementById("videoModal");
    document.getElementById("videoModal").play();
    ctamodal.style.display = "block";
    var check_controls_list = videoModal.hasAttribute('controlsList');
    var check_pip = videoModal.hasAttribute('disablePictureInPicture');
    if (!check_controls_list){
        videoModal.setAttribute('controlsList', 'nodownload');
    }
    if (!check_pip){
        videoModal.setAttribute('disablePictureInPicture', 'true');
    }
}

    const videoButtons = document.querySelectorAll(".video-btn");
    videoButtons.forEach((button) => {
        button.addEventListener("mouseover", () => {
            const ctaModal = document.getElementById("cta-modalvideo");
            const closeCta = document.getElementById("vidclose");
            const videoModal = document.getElementById("videoModal");
            const videoSource = document.getElementById("vidSource");
            closeCta.addEventListener("click", () => {
                videoModal.pause();
                ctaModal.style.display = "none";
            });
            document.body.addEventListener("click", (event) => {
                if (event.target === ctaModal) {
                    videoModal.pause();
                    ctaModal.style.display = "none";
                }
            });
            if (videoSource) {
                const dataSrc = videoSource.getAttribute("data-src");
                if (dataSrc) {
                    videoSource.src = dataSrc;
                    videoModal.load();
                }
            }
        });
    });

// Sub categories 
var categoriesCardWrapper = document.getElementsByClassName("categories-card-wrapper");
if (categoriesCardWrapper != null && categoriesCardWrapper.length > 0) {
    var stickySection = document.querySelector(".categories-sidebar"),
        navBar = document.querySelector(".topHeader").offsetHeight,

        stickyFixTop = resumeGetOffset(stickySection).top - navBar,
        staticStickyOffset = resumeGetOffset(stickySection).top;
    function resumeGetOffset(elem) {
        var rect = elem.getBoundingClientRect(),
            doc = elem.ownerDocument,
            docElem = doc.documentElement,
            win = doc.defaultView;
        return {
            top: rect.top + win.pageYOffset - docElem.clientTop,
            left: rect.left + win.pageXOffset - docElem.clientLeft
        };
    }
    function fixedCategoriesSidebar() {
        var w_width = document.documentElement.clientWidth || window.innerwidth;
        pageScroll = window.pageYOffset,
            goTopWithSticky = resumeGetOffset(document.querySelector(".go-top-wrapper")).top,
            stickySectionHeight = stickySection.offsetHeight,
            stickyFixBottom = goTopWithSticky - (staticStickyOffset + navBar + stickySectionHeight + 20);

        if (w_width > 767) {
            if (pageScroll >= staticStickyOffset - navBar) {
                stickySection.classList.add('sticky-sidebar');
                stickySection.style.top = (navBar + 10) + 'px';
            }
            else {
                stickySection.classList.remove('sticky-sidebar');
                stickySection.style.top = "auto";
            }
            if ((goTopWithSticky - (stickySectionHeight + navBar + 60)) < pageScroll) {
                stickySection.classList.add('go-top-with-sticky');
                stickySection.style.transform = "translate3d(0," + (stickyFixBottom) + "px, 0)";
                stickySection.style.top = "auto";
            }
            else {
                stickySection.classList.remove("go-top-with-sticky");
                stickySection.style.transform = "none";
            }
        }

    }
    window.addEventListener('scroll', function () {
        fixedCategoriesSidebar();

    });

}



function scrollingTop(elementY, duration) {
    var startingY = window.pageYOffset;
    var diff = elementY;
    var start;
    window.requestAnimationFrame(function step(timestamp) {
        if (!start) start = timestamp;
        var time = timestamp - start;
        var percent = Math.min(time / duration, 1);
        window.scrollTo(0, startingY + diff * percent);
        if (time < duration) {
            window.requestAnimationFrame(step);
        }
    })
}

var categoryWrapperId = document.querySelectorAll("#category-wrapper");
if (typeof categoryWrapperId != 'undefined' && categoryWrapperId != null && categoryWrapperId.length > 0) {
    var categoryLink = document.querySelectorAll('.category-link');
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");
    for (let i = 0; i < categoryLink.length; i++) {
        categoryLink[i].addEventListener('click', function (e) {
            if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            }
            else {
                e.preventDefault();
            }
            e.preventDefault();
            var href = e.target.getAttribute('href');
            var id = href.substr(1);
            var target = document.getElementById(id);
            var top = target.getBoundingClientRect().top;
            var topsum;
            var headerHeight = document.querySelector('.topHeader').offsetHeight;

            topsum = top - headerHeight - 10;
            scrollingTop(topsum, 150);
        });
    }

    var goTopWrapper = document.querySelector('.go-top-wrapper a');
    goTopWrapper.addEventListener('click', function (e) {
        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        }
        else {
            e.preventDefault();
        }
        e.preventDefault();
        var href = e.target.getAttribute('href');
        var id = href.substr(1);
        var target = document.getElementById(id);
        var top = target.getBoundingClientRect().top;
        var topsum;
        var headerHeight = document.querySelector('.topHeader').offsetHeight;
        topsum = top - headerHeight - 10;
        scrollingTop(topsum, 150);
    });
}
const copyButton = document.querySelector('.single_post_share_copy');
if (copyButton) {
    copyButton.addEventListener('click', function (e) {
        e.preventDefault();
        const linkElement = this.querySelector('a');
        if (linkElement) {
            linkElement.classList.add('expand-copy');
        }
        const linkCopyUrl = document.getElementById('link-copy-url');
        const isIpad = /iPad|iPod|Mac|iPhone/.test(navigator.platform);
        if (isIpad) {
            linkCopyUrl.setSelectionRange(0, 9999);
            document.execCommand('copy');
        } else {
            linkCopyUrl.select();
            document.execCommand('copy');
        }
        setTimeout(function () {
            if (linkElement) {
                linkElement.classList.remove('expand-copy');
            }
        }, 1000);
    });
}
